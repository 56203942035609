import loadable from '@loadable/component';
import React, { useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useCallback } from 'react';
import FullSizeSliderItem from './FullSizeSliderItem';
import useWindow from '../../../hooks/useWindow';

const Slider = loadable(() => import('react-slick'));

const settings = {
  autoplay: false,
  arrows: false,
  infinite: true,
  speed: 750,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 6000,
  pauseOnHover: false,
};

function FullSizeSliderSection({ sliderItems }) {
  const [sliderRef, setSliderRef] = useState(null);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const { isMobile, width } = useWindow();
  const [index, setIndex] = useState(0);
  const sliderWrapperRef = useRef(null);

  const getSliderRef = useCallback((node) => {
    setSliderRef(node);
  }, []);

  const generateSliderItems = useCallback(() => {
    const components = [];
    sliderItems.forEach((el, index) => {
      components.push(
        <FullSizeSliderItem
          key={index}
          title={el.title}
          firstDescription={el.firstDescription}
          secondDescription={el.secondDescription}
          image={el.image}
          activeIndex={index}
          navLength={sliderItems.length}
          setSlide={(index) => sliderRef.slickGoTo(index)}
          setSwipeDirection={setSwipeDirection}
        />
      );
    });

    return components;
  }, [sliderItems, sliderRef]);

  const getCentrallPadding = () => {
    if(width <= 1460) {
      return "70px"
    }
    else if(width <= 1650) {
      return "110px"
    }
    else if(width <= 2100) {
      return (width - 1436) / 1.6 + 'px'
    }
    else if(width <= 2800) {
      return (width - 1436) / 1.8 + 'px'
    }
    else if(width <= 3600) {
      return (width - 1436) / 1.85 + 'px'
    }
    return (width - 1436) / 1.9 + 'px'
  }

  useEffect(() => {
    if (!sliderWrapperRef?.current || !sliderRef) return;

    const handleMouseDown = () => {
      sliderRef.slickPause();
    };

    const handleMouseUp = () => {
      sliderRef.slickPlay();
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('touchstart', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchend', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('touchstart', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchend', handleMouseUp);
    };
  }, []);

  const updateIndex = (oldIndex, newIndex) => {
    if (oldIndex === 3 && newIndex === 0) {
      setIndex(4);
    } else {
      setIndex(newIndex);
    }
  };

  const isSwipable = useMemo(() => {
    if (!sliderRef) return true;
    if (index === 0) {
      if (swipeDirection === 'right') {
        sliderRef.slickGoTo(0);
      }
    }

    return true;
  }, [swipeDirection, sliderRef, index]);

  return (
    <div className={`full-size-slider-container` + ` active-index-${index}`} ref={sliderWrapperRef}>
      <Slider
        {...settings}
        ref={getSliderRef}
        centerMode={isMobile ? false : true}
        beforeChange={updateIndex}
        swipe={isSwipable}
        centerPadding={isMobile ? '50px' : getCentrallPadding()}
      >
        {generateSliderItems()}
      </Slider>
    </div>
  );
}

export default FullSizeSliderSection;
