import React, { useEffect, useRef } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import useWindow from '../../../hooks/useWindow';
import CustomImage from '../../Image/Image';
import { SecondaryDescription, SecondaryTitle } from '../../Typography';
import CustomHeadingSection from '../CustomHeadingSection';
import SectionWrapper from '../SectionWrapper';

function FullSizeSliderItem({
  title,
  firstDescription,
  secondDescription,
  image,
  navLength,
  activeIndex,
  setSlide,
  setSwipeDirection
}) {
  const { isMobile, isTablet, isDesktop } = useWindow();
  const containerRef = useRef()

  const generateNavItems = useCallback(() => {
    const components = [];
    const basicClassName = 'navigation__item';

    for (let i = 0; i < navLength; i++) {
      components.push(
        <div
          className={
            activeIndex === i ? `${basicClassName} ${basicClassName}--active` : basicClassName
          }
          onClick={() => setSlide(i)}
        />
      );
    }

    return components;
  }, [activeIndex, navLength, setSlide]);

  const imageClassName = useMemo(() => {
    if (activeIndex === navLength - 1) {
      return 'last-image';
    } else if (activeIndex === 0) {
      return 'first-image';
    }
    return null;
  }, [activeIndex, navLength]);

  useEffect(() => {
    if(!containerRef?.current) return 

    let oldX = null

    const onMouseMove = (e) => {
        if(e.pageX > oldX) {
            setSwipeDirection('right')
        }
        else {
            console.log('left')
            setSwipeDirection('left')
        }

        if(!oldX || Math.abs(e.pageX - oldX) > 5) {
            oldX = e.pageX
        }
    }

    containerRef.current.addEventListener('mousedown', () => {
        containerRef.current.addEventListener('mousemove', onMouseMove)
    })

    // containerRef.current.removeEventListener('mouseup', () => {
    //     containerRef.current.removeEventListener('mousemove', onMouseMove)
    // })
  }, [containerRef])

  return (
    <div className="full-size-slider" ref={containerRef}>
      <SectionWrapper>
        {isDesktop && <img className={imageClassName} src={image} />}
        <CustomHeadingSection
          leftContent={
            isTablet ? (
              <div className="text-content">
                <div className="text-wrapper">
                  <div>
                    {isMobile && <img src={image} />}
                    <SecondaryTitle>{title}</SecondaryTitle>
                    <SecondaryDescription>{firstDescription}</SecondaryDescription>
                    <SecondaryDescription>{secondDescription}</SecondaryDescription>
                  </div>
                  <div className="navigation__container">{generateNavItems()}</div>
                </div>
              </div>
            ) : null
          }
          rightContent={
            <>
              {isTablet ? (
                <img src={image} />
              ) : (
                <div className="text-content">
                  <div className="text-wrapper">
                    <div>
                      {isMobile && <img src={image} />}
                      {isMobile ? (
                        <div className="navigation__container">{generateNavItems()}</div>
                      ) : null}
                      <SecondaryTitle>{title}</SecondaryTitle>
                      <SecondaryDescription>{firstDescription}</SecondaryDescription>
                      <SecondaryDescription>{secondDescription}</SecondaryDescription>
                    </div>
                    {!isMobile ? (
                      <div className="navigation__container">{generateNavItems()}</div>
                    ) : null}
                  </div>
                </div>
              )}
            </>
          }
        />
      </SectionWrapper>
    </div>
  );
}

export default FullSizeSliderItem;
